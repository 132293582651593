import Modal from './modules/Modal.js';

const modal = new Modal();

(function() {
  const subnav = document.querySelectorAll('.offices__subnav--link');

  [...subnav].forEach((link, i) => {
    const target = document.getElementById(link.dataset.link);
    let offset = target.offsetTop - 60;
    if(i == 0) {
      offset = 0;
    }
    link.addEventListener('click', e => {
      window.scrollTo({
        'top': offset,
        'behavior':'smooth'
      });
    })
  });
})();

(function() {
  const subnav = document.querySelector('.offices__subnav');
  const sticky = subnav && subnav.offsetTop;
  if(sticky) {
    document.addEventListener('scroll', e => {
      if(window.pageYOffset > sticky) {
        subnav.classList.add('offices__subnav--sticky');
      } else {
        subnav.classList.remove('offices__subnav--sticky');
      }
    });
  }
})();


