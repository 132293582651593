class Modal {
  constructor() {
    this.modal = document.querySelectorAll('.modal');
    this.modalClose = document.querySelectorAll('.modal--close');
    this.events();
  }

  events() {
    window.addEventListener('load', e => {
      if(this.urlVarExists('success')) {
        this.showModal();
      }
    });
    [...this.modalClose].forEach((btn) => {
      btn.addEventListener('click', this.hideModal.bind(this));
    });
  }

  showModal(e) {
    [...this.modal].forEach(m => {
      m.classList.add('modal--show');
      setTimeout(() => {
        m.classList.add('modal--fade');
      }, 100);
    });
  }

  hideModal(e) {
    const modal = e.target.parentNode.parentNode;
    const url = location.pathname;

    modal.classList.remove('modal--fade');
    setTimeout(() => {
      modal.classList.remove('modal--show');
      window.history.replaceState({}, document.title, url);
    }, 400);

  }

  urlVarExists(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) { return true; }
    }
    return false;
  }

  getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
  }
}

export default Modal;
